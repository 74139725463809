<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile" v-model="showDialog" width="400" id="task-dialog">
    <v-sheet id="message-view-popup" class="pa-6 pa-md-10 view-popup" color="white">
      <div class="d-flex gap-10">
        <div>
          <h2 class="mb-5">Upload file</h2>
        </div>
        <h2 class="ml-auto">
          <v-btn @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </h2>
      </div>
      <div>
        <v-file-input
            label="Attach File"
            outlined
            v-model="form.file"
            capture="user"
        ></v-file-input>
      </div>
      <div class="mt-2 d-flex">
        <v-btn class="mr-auto" @click="showPopup = false">Cancel</v-btn>
        <v-btn color="primary" @click="submit">Submit</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/bootstrap/EventBus";
import filesApi from "@/api/modules/files"

export default {
  name: 'FileUploadPopup',
  data() {
    return {
      showDialog: false,
      form: {
        file: null,
      }
    }
  },
  created: function () {
    EventBus.$on('file-upload-popup', () => {
      this.showDialog = true;
    });
  },
  methods: {
    submit() {
      const payload = new FormData();
      payload.append('fileable_id', this.$route.params.id);
      payload.append('fileable_type', this.$route.name === 'customers.attachments' ? "App\\Models\\Customers\\Customer" : "App\\Models\\User");
      payload.append('file', this.form.file);

      filesApi.storeFile(payload)
        .then(() => {
          EventBus.$emit('load-attachments');
          this.showDialog = false
          this.form.file = null
        })
    }
  }
}
</script>